import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import DeviceCases from './DeviceCases'
import { LocalizationType } from '../types/localization'
const CaseList = React.lazy(() => import('../mobile/index'))

interface IContext {
  dir?: string
  localization?: LocalizationType
  environment?: any
}

const RootComponent = (props) => {
  const theme = useTheme()
  const { dir, environment } = useContext<IContext>(RootContext)

  return (
    <ThemeProvider theme={{ ...environment, ...theme, dir }}>
      {environment?.isMobile ? (
        <CaseList
          device={props.device}
          refresh={props?.refresh}
          Skelton={props?.Skelton}
          type={props?.type}
        />
      ) : (
        <DeviceCases
          device={props.device}
          refresh={props?.refresh}
          Skelton={props?.Skelton}
        ></DeviceCases>
      )}
    </ThemeProvider>
  )
}

export default RootComponent
