import { useEffect, useRef, useState } from 'react'
import useMethoneClient from '../api/methone'
import {
  Case,
  ICasesRequest,
  ICasesResponse
} from '@jarvis/web-support-commons/dist/methone/cases/types'
import useContextAPI from './useContextAPI'

interface ICases {
  openCases: Case[]
  closedCases: Case[]
}
const useCaseApi = ({ DeviceId, refresh }) => {
  const showLoaderRef = useRef(true)
  const MethoneClient = useMethoneClient()
  const { authProvider } = useContextAPI()
  const { isSignedIn, auth, refreshToken } = authProvider
  //const { cases } = store?.state || {}

  const [loading, setLoading] = useState(false)
  const [cases, setCases] = useState<ICases>()

  useEffect(() => {
    if (DeviceId != undefined && DeviceId != 0 && isSignedIn && auth && refresh)
      fetchCases(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (DeviceId != undefined && DeviceId != 0 && isSignedIn && auth)
      fetchCases(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceId, isSignedIn])

  const fetchCases = async (showLoader) => {
    try {
      setLoading(showLoader ? showLoaderRef?.current : false)
      const caseRequest: ICasesRequest = {
        DeviceId: DeviceId
      }
      await refreshToken()
      const cases = await MethoneClient.getCases(caseRequest)
      const filteredCase = getFilteredCase(cases)
      setCases({ ...filteredCase })
    } catch (e) {
      console.log(e)
      setCases({ openCases: [], closedCases: [] })
    } finally {
      setLoading(false)
      showLoaderRef.current = false
      //setCases(getFilteredCase(casesMock))
    }
  }

  const getFilteredCase = (cases: ICasesResponse): ICases => {
    let sortedCase = cases?.Cases
    try {
      sortedCase = cases?.Cases.sort(
        (a, b) =>
          new Date(b.DateCreated)?.getTime() -
          new Date(a.DateCreated)?.getTime()
      )
      // eslint-disable-next-line no-empty
    } catch (e) {}
    const openCases = sortedCase?.filter((e) => e.Status === 0) || []
    const closedCases = sortedCase?.filter((e) => e.Status !== 0) || []
    return {
      openCases,
      closedCases
    }
  }

  return {
    loading,
    cases
  }
}

export default useCaseApi

// const casesMock = {
//   FaultItemList: [],
//   Cases: [
//     {
//       CaseId: '5150432343',
//       Status: 2,
//       StatusText: 'Pending Closure',
//       DateCreated: '2019-06-02T21:41:03Z',
//       DateUpdated: '2019-03-22T11:41:03Z',
//       DateClosed: '',
//       HasUpdates: true
//     },
//     {
//       CaseId: '5001803177',
//       Status: 1,
//       StatusText: 'Closed',
//       DateCreated: '2017-03-12T23:41:03Z',
//       DateUpdated: '2018-05-22T21:41:03Z',
//       DateClosed: '',
//       HasUpdates: false
//     }
//   ]
// }
