import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import card from '@veneer/core/dist/esm/scripts/card'
import HPXTheme, { left, right } from '../../styles/global'

import CollapsibleBlock from '../Toggle'

export const Toggle = styled(CollapsibleBlock)`
  display: flex;
  position: relative;
  &.full-width {
    display: flex;
    width: 100%;
  }
`

const flexRow = css`
  display: flex;
  flex-direction: row;
`

const flexColumn = css`
  display: flex;
  flex-direction: column;
`

const defaultTitle = css`
  text-align: ${left};
  ${flexRow};
  font-style: normal;
  font-feature-settings: 'rclt' off;
`

export const Section = styled.div`
  min-height: auto;
  ${flexColumn};
  .m-b-8 {
    margin-bottom: ${Tokens.space2};
  }
  .colorGray5 {
    color: ${({ theme }) => theme.colorForegroundLight};
  }
`
export const Box = styled.div`
  display: flex;
  ${({ theme }) =>
    css`
      margin: ${Tokens.space3};
      padding: ${Tokens.space3};
      background: ${theme.colorBackgroundContainer};
      background-color: ${theme.colorBackgroundContainer};
      border-radius: ${Tokens.borderRadius4};
    `}
`
export const Container = styled.div`
  min-height: auto;
  ${flexColumn};
  margin: ${Tokens.space2} 0px;
  .m-b-8 {
    margin-bottom: ${Tokens.space2};
  }
  .m-b-16 {
    margin-bottom: ${Tokens.space4};
  }
  .m-l-8 {
    margin-${left}: ${Tokens.space2};
  }
  .m-l-60 {
    margin-${left}: calc(${Tokens.space8} + ${Tokens.space7});
  }
  .align-center {
    align-items: center;
  }
`

export const PageTitle = styled.h6`
  ${defaultTitle}
`

export const Card = styled(card)`
  &.cardStyle {
    background: ${({ theme }) => theme.colorBackgroundContainer};
    background-color: ${({ theme }) => theme.colorBackgroundContainer};
    border-color: ${({ theme }) => theme.colorBorder};
    border-radius: ${({ theme }) => theme.borderRadius3};
    border-width: 1px !important;
    &:hover {
      ${({ theme }) =>
        theme.mode === 'light' &&
        css`
          border-color: ${({ theme }) => theme.colorBorder} !important;
        `}
    }
  }
  &.pointer {
    cursor: pointer;
  }
  padding: ${Tokens.space4} ${Tokens.space6};
  ${(props) =>
    props.theme.isMobile &&
    css`
      padding: ${Tokens.space2} ${Tokens.space4};
    `}
  transition: 0.25s padding ease-out;
  ${(props) =>
    props.status &&
    !props.theme.isMobile &&
    css`
      padding: ${Tokens.space2} ${Tokens.space6};
    `}
  position: relative;
  &.p-8 {
    padding: ${Tokens.space3} ${Tokens.space6};
  }
  display: flex;
  margin: ${Tokens.space2} 0px;
  > div {
    width: 100%;
  }
  .break-text {
    white-space: pre-line;
    word-break: break-all;
  }
`

export const Timeline = styled.ul`
  position: relative;
  padding-${left}: 45px;
  list-style: none;
  border-bottom: 1px solid #fafafa;
  &.defaultView {
    height: 100%;
    li {
      height: 100%;
    }
  }
`

export const Item = styled.li`
  position: relative;
  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 30px;
    ${left}: -24px;
    width: 10px;
    height: calc(100% - 20px);
    border-${left}: 2px dashed #ccc;
  }

  &:last-child {
    &::before {
      height: calc(100%);
    }
  }
  &::after {
    content: attr(data-index);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    ${left}: -36px;
    width: 22px;
    height: 22px;
    border: 2px solid ${({ theme }) => theme.colorForegroundLight};
    border-radius: 50%;
    background-color: #fff;
  }
  &:first-child {
    &::before {
      top: 30px;
    }
  }
  ${(props) =>
    props.isCollapsed === false &&
    css`
      &:last-child {
        &::before {
          border-${left}: 0px;
        }
      }
    `}
  ${(props) =>
    props.isDone === false &&
    css`
      &::after {
        content: attr(data-index);
        padding-top: 1px;
        width: 22px;
        height: 22px;
        ${left}: -36px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: ${({ theme }) => theme.colorForegroundLight};
        border: 2px solid ${({ theme }) => theme.colorForegroundLight};
        background-color: white;
      }
      &::before {
        top: -8px;
        height: calc(100% + 14px);
      }
    `}
  ${(props) =>
    props.isDone === true &&
    css`
      &::before {
        border-${left}: 2px solid ${({ theme }) => theme.colorPrimary};
      }
      &::after {
        content: attr(data-index);
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        border: 2px solid ${({ theme }) => theme.colorPrimary};
        background-color: ${({ theme }) => theme.colorPrimary};
      }
    `}
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 20px;
  ${(props) =>
    props.show === true &&
    css`
      border-bottom: 1px solid #cccccc;
    `}
`

export const Header = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  color: ${({ theme }) => theme.colorForeground};
  &.flexEnd {
    justify-content: flex-end;
  }
`
export const Title = styled.span`
  color: ${({ theme }) => theme.colorForegroundMedium};
  flex: 1;
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colorForeground};
`
export const Row = styled.div`
  display: flex;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
    ${(props) =>
      props.align &&
      css`
        align-items: ${props.align};
      `}
      ${(props) =>
        props.width &&
        css`
          width: 100%;
        `}
  &.m-t-20 {
    margin-top: ${Tokens.space5};
  }
  &.m-t-32 {
    margin-top: ${Tokens.space8};
  }
  &.m-b-30 {
    margin-bottom: calc(${Tokens.space7} + 2px);
  }
  &.m-t-8 {
    margin-top: ${Tokens.space2};
  }
  ${HPXTheme.mediaQueries.S} {
    &.sm-hide {
      display: none;
    }
  }
  &.row {
    position: relative;
    .col-6 {
      width: 50%;
      &:first-child {
        ${HPXTheme.mediaQueries.XL} {
          padding-${right}: calc(${Tokens.space8} + ${Tokens.space6});
        }
        ${HPXTheme.mediaQueries.L} {
          padding-${right}: ${Tokens.space6};
        }
        ${HPXTheme.mediaQueries.M} {
          padding-${right}: ${Tokens.space4};
        }
      }
    }
    .media-small,
    .line {
      display: none;
    }
    .close-case {
      margin-top: calc(${Tokens.space8} + ${Tokens.space7});
      overflow: unset !important;
      margin-bottom: ${Tokens.space1};
    }
    ${HPXTheme.mediaQueries.M} {
     .col-md-5 {
      width: 45% !important;
     } .col-md-7 {
      width: 55% !important;
     }
    }

    ${HPXTheme.mediaQueries.S} {
      flex-wrap: wrap;
      .col-6 {
        width: 100%;
      }
      .media-large {
        display: none;
      }
      .media-small {
        display: flex;
      }
      .close-case {
        margin-top: ${Tokens.space6};
        margin-bottom: ${Tokens.space8};
      }
      .line {
        display: flex;
        margin-bottom: ${Tokens.space8};
        hr {
          width: 100%;
          border: 0px;
          border-bottom: 1px solid #cccccc;
        }
      }
    }
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  position:relative;
  &.center {
    align-items: center;
  }
  ${(props) =>
    props.align &&
    css`
      justify-content: ${props.align};
    `}
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.width &&
    css`
      flex: 0 ${props.width};
      max-width: ${props.width};
    `}
  &.p-r-16 {
    padding-${right}: ${Tokens.space4};
  }
  &.m-t-4 {
    margin-top: ${Tokens.space4}
  }
  &.m-t-6 {
    margin-top: ${Tokens.space6}
  }
`
export const Label = styled.span`
  flex: 1;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colorForegroundLight};
`

export const Value = styled.span`
  margin-bottom: 4px;
  ${(props) =>
    props.medium &&
    css`
      color: ${({ theme }) => theme.colorForegroundMedium};
    `}
  ${(props) =>
    !props.medium &&
    css`
      color: ${({ theme }) => theme.colorForeground};
    `}
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`
export const Line = styled.hr`
  border: 0px;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  margin-bottom: ${Tokens.space4};
`
export const Text = styled.span`
  color: ${({ theme }) => theme.colorForeground};
  &.m-b-8 {
    margin-bottom: ${Tokens.space2};
  }
`
export const Spinner = styled.div`
  position: absolute;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 100%;
  width: 100%;
`

export const FloatingButton = styled.a`
  position: absolute !important;
  ${right}: 0px;
`
