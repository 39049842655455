import styled, { css } from 'styled-components'
import Tokens from '@veneer/tokens'
import accordion from '@veneer/core/dist/esm/scripts/accordion'
import HPXTheme, { left } from '../../styles/global'

export const Accordion = styled(accordion)`
  &.timeline {
    > div {
      background: ${({ theme }) => theme.colorBackgroundContainer}
    }
    padding-${left}: 60px;
    &:first-child {
      margin-top: 0px;
    }
    > div {
      ${(props) =>
        props.isChild == false &&
        props.show == false &&
        css`
          border-radius: ${Tokens.borderRadius4};
          border-width: 1px;
        `}
      ${(props) =>
        props.isChild == false &&
        css`
          &:first-child {
            &::after {
              top: 0px;
            }
            &::before {
              top: 30px;
              height: calc(100% - 20px);
            }
            >div: first-child {
              padding-top: ${Tokens.space2};
              padding-bottom: ${Tokens.space2};
              align-items: flex-start;
              > svg {
                margin-top: -${Tokens.space2};
              }
            }
          }
        `}
        ${(props) =>
          props.show == false &&
          css`
            &:not(:first-child) {
              display: none;
            }
          `}

          ${HPXTheme.mediaQueries.S} {
            ${(props) =>
              props.isChild == false &&
              css`
                &:first-child {
                  &::after {
                    top: ${({ theme }) => (theme.isMobile ? '0px' : '15px')};
                  }
                  &::before {
                    top: ${({ theme }) => (theme.isMobile ? '35px' : '45px')};
                    height: ${({ theme }) =>
                      theme.isMobile
                        ? `calc(100% - 25px)`
                        : `calc(100% - 35px)`};
                  }
                  >div: first-child {
                    padding-top: ${Tokens.space5};
                    padding-bottom: ${Tokens.space5};
                    align-items: flex-start;
                    > svg {
                      margin-top: unset;
                    }
                  }
                }
              `}
              ${(props) =>
                props.show == false &&
                css`
                  &: not(: first-child) {
                    display: none;
                  }
                `}
          }
      > div: first-child {
        padding-top: ${Tokens.space5};
        padding-bottom: ${Tokens.space5};
        align-items: flex-start;
      }
      > :first-child {
        > :first-child {
          margin: unset;
          flex: 1;
        }
        > svg {
          fill: ${({ theme }) => theme.colorPrimary};
        }
      }
      position: relative;
      overflow: unset;
      ${HPXTheme.mediaQueries.S} {
        ${(props) =>
          props.show == false &&
          css`
            height: 120px;
          `}
      }
      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: ${({ theme }) => (theme.isMobile ? '18px' : '15px')};
        ${left}: -60px;
        ${HPXTheme.mediaQueries.M} {
          ${left}: -48px;
        }
        ${HPXTheme.mediaQueries.S} {
          ${left}: -48px;
        }
        width: 22px;
        height: 22px;
        border: 2px solid ${Tokens.colorGray5};
        border-radius: 50%;
        background-color: ${Tokens.colorWhite};
        font-size: ${Tokens.fontSize2};
      }
      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: ${({ theme }) => (theme.isMobile ? '48px' : '45px')};
        ${left}: -48px;
        ${HPXTheme.mediaQueries.M} {
          ${left}: -36px;
        }
        ${HPXTheme.mediaQueries.S} {
          ${left}: -36px;
        }
        width: 10px;
        height: calc(100% - 35px);
        border-${left}: 2px dotted ${Tokens.colorGray5};
      }
      ${(props) =>
        props.isChild == false &&
        props.show == false &&
        props.status != 0 &&
        css`
          &::before,
          &::after {
            display: none;
          }
        `}
        ${(props) =>
          props.theme.isMobile &&
          css`
            &::before,
            &::after {
              display: block;
            }
          `}
        ${(props) =>
          props.isChild == false &&
          props.status != 0 &&
          css`
            &:first-child {
              >div: first-child {
                padding-top: ${Tokens.space1};
                padding-bottom: ${Tokens.space1};
              }
            }
          `}
    }
    ${(props) => createCSS(props)};
  }
`

const isNotDone = (index, props) => `
  &::after {
    content: "${index}";
    color:  ${props?.theme?.colorForegroundLight};
    border: 2px solid ${Tokens.colorGray5};
    background-color:  ${props?.theme?.colorBackgroundContainer};
    display: flex;
    justify-content: center;
    align-items: center;

  }
`

const isDone = (index, props) => `
  &::before {
    border-${left(props)}: 2px dotted ${props?.theme?.colorPrimary};
  }
  &::after {
    content:"${index}";
    font-size: 12px;
    font-weight: bold;
    color: ${props?.theme?.colorForegroundInverse};
    text-align: center;
    border: 2px solid ${props?.theme?.colorPrimary};
    background-color: ${props?.theme?.colorPrimary};
  }
`

function createCSS(props) {
  let styles = ''

  for (let i = 0; i < props.cases.length; i++) {
    styles += `
    > div:nth-child(${i + 1}) {
        ${
          i == 0 || props.cases[i].ServiceOrderHistoryId != 0
            ? isDone(i + 1, props)
            : isNotDone(i + 1, props)
        }
       }
     `
  }

  return css`
    ${styles}
  `
}
