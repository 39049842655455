import { useState } from 'react'
import useMethoneClient from '../api/methone'
import { formatCase } from '../utils'
import { ICaseDetailRequest } from '@jarvis/web-support-commons/dist/methone/cases/types'
import useContextAPI from './useContextAPI'
// import { History } from '@jarvis/web-support-commons/dist/methone/cases/types/casedetails'

// interface IException {
//   [key: string]: History[]
// }

const useCaseDetails = ({ CaseId }) => {
  const MethoneClient = useMethoneClient()
  //const { cases } = store?.state || {}
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [isCloseRequested, setIsCloseRequested] = useState(false)
  const [caseDetails, setCaseDetails] = useState({
    caseData: null,
    history: null,
    exception: null
  })
  const { authProvider } = useContextAPI()
  const { refreshToken } = authProvider

  const fetchCaseDetails = async (CaseId) => {
    try {
      setLoading(true)
      if (caseDetails?.history?.length == 0 || !caseDetails?.caseData) {
        const caseRequest: ICaseDetailRequest = {
          CaseID: CaseId
        }
        await refreshToken()
        const caseDetails = await MethoneClient.getCaseDetails(caseRequest)
        //if (caseDetails?.FaultItemList?.length > 0) return
        const { caseData, exceptionData } = formatCase({
          ...caseDetails
        })
        const { ServiceOrders } = caseData
        const history =
          ServiceOrders && ServiceOrders[0].History.length > 0
            ? ServiceOrders[0].History
            : undefined
        setCaseDetails({
          caseData: caseData,
          history: history,
          exception: exceptionData
        })
        // }
      } else {
        //setShow(!show)
      }
      // if (store) {
      //   store.setState({ cases })
      // }
    } catch (e) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   if (show) fetchCaseDetails(CaseId)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [CaseId, show])

  const closeCase = async () => {
    setLoading(true)
    await MethoneClient.closeCase({ CaseId })
    //await fetchCaseDetails(CaseId)
    setIsCloseRequested(true)
    setLoading(false)
  }

  return {
    show,
    setShow,
    loading,
    caseDetails,
    closeCase,
    isCloseRequested,
    fetchCaseDetails
  }
}

export default useCaseDetails
/*
const casesDetailsMock: ICaseDetailResponse = {
  FaultItemList: [],
  Case: {
    CaseID: '5150161961',
    CaptionOpen: '',
    CaptionClose: '',
    Status: 0,
    StatusText: '',
    DateClosed: '2019-11-08T11:11:05Z',
    ProductNumber: '',
    Description:
      'My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display',
    //CaseStatus: 'Open',
    CreatedDateTime: '2019-04-23',
    ProductName: 'HP ZBook 15 G2',
    SerialNumber: 'CND5252BVP',
    Nickname: 'Living room printer',
    ServiceOrders: [
      {
        ServiceOrderId: 'BLJJ504492',
        OrderEntryDate: new Date(),
        FlowType: 'ExchangeFlow',
        History: [
          {
            ServiceOrderHistoryId: '5938',
            ChangeDate: '2019-11-08T11:11:05Z',
            SOStatus: 'SP',
            Caption: 'Service order created',
            Title:
              'Thank you for contacting HP Support. We have opened a support case for a replacement order for your product. A replacement product will be shipped to you within 2 to 5 business days.',
            // ReplacementStatus: 'SP',
            // LangCode: 'EN',
            // OrderEntryDate: '2019-11-08T19:10:28Z',
            // ServiceType: 'R__NR1',
            // OutboundAirBillNo: null,
            // ShipMethod: 'U2',
            // IsOpen: true,
            // ShipMethodDescription:
            //   'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
            // InboundAirBillNo: null,
            // InShipMethod: 'U5',
            // InShipMethodDescription:
            //   'UPS Worldwide Express Plus Add Some Randdom Test Sting Bond. James Bond.',
            // BoxAirBillNo: null,
            // BoxShipMethod: 'F2',
            // BoxShipMethodDescription:
            //   'FED-EX SECOND DAY Add Some Randdom Test Sting Keep your friends close, but your enemies closer.',
            // ShipDate: null,
            // DeliveryDate: null,
            // ActualDeliveryDate: null,
            // Status: 0,
            Tracking: [
              {
                TrackingNumber: '',
                ShipDescription:
                  'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
                Status: 0,
                StatusDate: null,
                StatusText: '',
                Carrier: '',
                EstDeliveryDate: '',
                TrackingURL: ''
              }
            ]
          },
          {
            ServiceOrderHistoryId: '5939',
            ChangeDate: '2019-11-08T11:11:06Z',
            SOStatus: 'SC',
            Caption: 'Replacement shipped',
            Title: 'HP has shipped your replacement product.',
            //ReplacementStatus: 'SP',
            //LangCode: 'EN',
            // OrderEntryDate: '2019-11-08T19:10:37Z',
            // ServiceType: 'ISAGM3',
            // OutboundAirBillNo: '789798442180',
            // ShipMethod: 'F1',
            // ShipMethodDescription:
            //   'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
            // InboundAirBillNo: '789798442661',
            // InShipMethod: 'F1',
            // InShipMethodDescription:
            //   'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting Carpe diem. Seize the day, boys.',
            // BoxAirBillNo: null,
            // BoxShipMethod: 'F3',
            // BoxShipMethodDescription:
            //   "FEDEX INTERNATIONAL PRIORITY 1-3 DAYS Add Some Randdom Test Sting I am serious. And don't call me Shirley.",
            // ShipDate: '2019-11-08T19:10:40Z',
            // DeliveryDate: null,
            // ActualDeliveryDate: null,
            Tracking: [
              {
                TrackingNumber: '',
                ShipDescription:
                  'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
                Status: 0,
                StatusDate: null,
                StatusText: '',
                Carrier: '',
                EstDeliveryDate: '',
                TrackingURL: ''
              }
            ]
          },
          {
            ServiceOrderHistoryId: '0',
            ChangeDate: '2019-11-08T11:11:06Z',
            SOStatus: 'SC',
            Caption: 'Replacement shipped',
            Title: 'HP has shipped your replacement product.',
            // ReplacementStatus: 'SP',
            // LangCode: 'EN',
            // OrderEntryDate: '2019-11-08T19:10:37Z',
            // ServiceType: 'ISAGM3',
            // OutboundAirBillNo: '789798442180',
            // ShipMethod: 'F1',
            // ShipMethodDescription:
            //   'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
            // InboundAirBillNo: '789798442661',
            // InShipMethod: 'F1',
            // InShipMethodDescription:
            //   'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting Carpe diem. Seize the day, boys.',
            // BoxAirBillNo: null,
            // BoxShipMethod: 'F3',
            // BoxShipMethodDescription:
            //   "FEDEX INTERNATIONAL PRIORITY 1-3 DAYS Add Some Randdom Test Sting I am serious. And don't call me Shirley.",
            // ShipDate: '2019-11-08T19:10:40Z',
            // DeliveryDate: null,
            // ActualDeliveryDate: null,
            // Status: 0,
            Tracking: [
              {
                TrackingNumber: '',
                ShipDescription:
                  'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
                Status: 0,
                StatusDate: null,
                StatusText: '',
                Carrier: '',
                EstDeliveryDate: '',
                TrackingURL: ''
              }
            ]
          },
          {
            ServiceOrderHistoryId: '0',
            ChangeDate: '2019-11-08T11:11:06Z',
            //SOStatus: 'SC',
            Caption: 'Replacement shipped',
            Title: 'HP has shipped your replacement product.',
            //ReplacementStatus: 'SP',
            //LangCode: 'EN',
            //OrderEntryDate: '2019-11-08T19:10:37Z',
            //ServiceType: 'ISAGM3',
            //OutboundAirBillNo: '789798442180',
            //ShipMethod: 'F1',
            //ShipMethodDescription:
            // 'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
            // InboundAirBillNo: '789798442661',
            // InShipMethod: 'F1',
            // InShipMethodDescription:
            //  'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting Carpe diem. Seize the day, boys.',
            // BoxAirBillNo: null,
            // BoxShipMethod: 'F3',
            // BoxShipMethodDescription:
            //  "FEDEX INTERNATIONAL PRIORITY 1-3 DAYS Add Some Randdom Test Sting I am serious. And don't call me Shirley.",
            // ShipDate: '2019-11-08T19:10:40Z',
            //DeliveryDate: null,
            // ActualDeliveryDate: null,
            SOStatus: '',
            Tracking: [
              {
                TrackingNumber: '',
                ShipDescription:
                  'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
                Status: 0,
                StatusDate: null,
                StatusText: '',
                Carrier: '',
                EstDeliveryDate: '',
                TrackingURL: ''
              }
            ]
          }
        ],
        Exceptions: [
          {
            ServiceOrderHistoryId: '5940',
            ChangeDate: new Date(),
            SOStatus: 'DP',
            Caption: 'Defective return pending',
            Title: 'Defective return pending',
            Description:
              'Please follow the packing and shipping instructions to return your defective part to HP.'
          },
          {
            ServiceOrderHistoryId: '5941',
            ChangeDate: new Date(),
            SOStatus: 'RC',
            Caption: 'Defective product/part received',
            Title: 'Defective product/part received',
            Description:
              'The HP Repair Facility has received your defective part.'
          },
          {
            ServiceOrderHistoryId: '5942',
            ChangeDate: new Date(),
            SOStatus: 'CH',
            Caption: 'Please contact HP',
            Title:
              'Your order could not be processed using the information we collected. We are attempting to contact you. If we have not done so, please contact HP to continue with your order. Refer to this Case # [CaseId] when speaking to a support agent.',
            Description:
              'Important: If we are unable to reach you, by phone and/or e-mail within 3 business days, your unit will be returned to you unrepaired.(due to credit card issues which customer needed more time. Agents will follow-up with these customers)'
          },
          {
            ServiceOrderHistoryId: '5943',
            ChangeDate: new Date(),
            SOStatus: 'CR',
            Caption: 'Please contact HP',
            Title:
              'The HP service center needs information from you to continue with the repairs on your PC. We are attempting to contact you. If we have not done so, please contact HP to continue with your order. Refer to this Case # [CaseId] when speaking to a support agent.',
            Description:
              'Important: If we are unable to reach you, by phone and/or e-mail within 3 business days, your unit will be returned to you unrepaired.(if customer needs to provide an answer or response this status is used)'
          },
          {
            ServiceOrderHistoryId: '5944',
            ChangeDate: new Date(),
            SOStatus: 'OH',
            Caption: 'On hold',
            Title:
              'Your order is currently on hold. HP will resolve the issue and resume processing within 24 hours.                  ',
            Description:
              'There is no need to contact HP. If we need to reach you a representative will contact you.(10% of all orders go on hold)'
          },
          {
            ServiceOrderHistoryId: '5945',
            ChangeDate: new Date(),
            SOStatus: 'OC',
            Caption: 'Order cancelled',
            Title: 'This order was cancelled. No further action is required.',
            Description: 'ServiceOrderExceptionDescription_OCText'
          },
          {
            ServiceOrderHistoryId: '5948',
            ChangeDate: new Date(),
            SOStatus: 'OC',
            Caption: 'Order cancelled',
            Title: 'This order was cancelled. No further action is required.',
            Description: 'ServiceOrderExceptionDescription_OCText'
          },
          {
            ServiceOrderHistoryId: '5949',
            ChangeDate: new Date(),
            SOStatus: 'OC',
            Caption: 'Order cancelled',
            Title: 'This order was cancelled. No further action is required.',
            Description: 'ServiceOrderExceptionDescription_OCText'
          }
        ]
      }
    ]
  }
}
*/
