import React from 'react'
import { ShellProps } from '../src/types/shell'
import { RootComponent } from '../src'
import { ThemeProvider } from '@veneer/theme'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import resources from '../src/assets/locale'
import { DirectionProvider } from '@veneer/core/dist/scripts/direction'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'
import {
  BaseScreenPath,
  DeviceScreenName,
  DefaultScreenName,
  SupportDevice,
  Support
} from '../src/utils/constants'
declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root({ ...props }) {
  const { v1 } = window.Shell as ShellProps
  const namespace = '@jarvis/react-hpx-support'
  const theme = props?.themeProvider || {}
  return (
    <section id={namespace}>
      <JarvisAnalyticsContextProvider
        initialScreenData={{
          screenName: DefaultScreenName,
          screenPath: `${BaseScreenPath}/${Support}/${SupportDevice}/`,
          activity: `${DeviceScreenName}-v01`
        }}
      >
        <DirectionProvider direction={theme.dir}>
          <ThemeProvider {...theme}>
            <RootProvider
              localization={v1.localization}
              resources={resources}
              MethoneClient={props.MethoneClient}
              formatAutoId={props?.formatAutoId}
              authProvider={props.authProvider}
              environment={props?.environment}
              navigation={props?.navigation}
              dir={theme.dir}
              navigatePath={props?.navigatePath}
            >
              <RootComponent
                device={props.device}
                refresh={props?.refresh}
                Skelton={props?.Skelton}
                type={props?.type}
              />
            </RootProvider>
          </ThemeProvider>
        </DirectionProvider>
      </JarvisAnalyticsContextProvider>
    </section>
  )
}
