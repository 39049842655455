export const Support = 'Support'
export const SupportDevice = 'Supportdevice'
export const BaseScreenPath = '/HpxWinClient'
export const DefaultScreenName = 'SupportCases'
export const DeviceScreenName = 'DeviceSupportCases'

export const TestIDPrefix = 'Supp'
export const ContainerComponent = 'CasesComponent'
export const MFEname = 'DeviceSupportCases'
export const DeviceComponent = 'DeviceComponent'
export const ProductIconComponent = 'ProductIconBtnComponent'
