import React from 'react'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import {
  Card,
  Column,
  Container,
  Header,
  PageTitle,
  Section,
  Text
} from './styles'
import CaseCard from '../CaseCard'
import useCaseApi from '../../hooks/useCaseApi'
import useContextAPI from '../../hooks/useContextAPI'

const DeviceCases = ({ device, refresh, Skelton }) => {
  const { DeviceId } = device
  const { cases, loading } = useCaseApi({ DeviceId, refresh })
  const { t } = useI18n()
  const { formatAutoId } = useContextAPI()

  if (loading && Skelton) return <Skelton></Skelton>

  return (
    <>
      <Section>
        <Column>
          <Header className="m-b-8">
            <PageTitle
              id={formatAutoId('case-title')}
              tabIndex={-1}
              className="subtitle-regular"
            >
              {t('common.header', 'Your support cases')}
            </PageTitle>
          </Header>
          <Container>
            <Text
              className="caption-small m-b-8"
              id={formatAutoId('open-cases-title')}
              tabIndex={-1}
            >
              {t('common.openCases', 'Open cases')}
            </Text>
            {cases?.openCases?.length > 0 &&
              cases?.openCases.map((item) => (
                <CaseCard
                  item={item}
                  key={item.CaseId}
                  device={device}
                ></CaseCard>
              ))}
            {cases?.openCases?.length == 0 && (
              <Card
                className="p-8 m-b-16 cardStyle"
                content={
                  <span
                    className="caption-small colorGray5"
                    id={formatAutoId('open-cases-desc')}
                    tabIndex={-1}
                  >
                    {t(
                      'common.thereAreNoOpenCases',
                      'There are currently no open cases'
                    )}
                  </span>
                }
              />
            )}
          </Container>
          {cases?.closedCases?.length > 0 && (
            <Container>
              <Text
                className="caption-small m-b-8"
                id={formatAutoId('closed-cases-title')}
                tabIndex={-1}
              >
                {t('common.closedCases', 'Closed cases')}
              </Text>
              {cases?.closedCases?.length > 0 &&
                cases.closedCases.map((item) => (
                  <CaseCard
                    item={item}
                    key={item.CaseId}
                    device={device}
                  ></CaseCard>
                ))}
            </Container>
          )}
          {cases?.openCases?.length > 0 && cases?.closedCases?.length == 0 && (
            <Container>
              <Text
                className="caption-small m-b-8"
                id={formatAutoId('closed-cases-title')}
                tabIndex={-1}
              >
                {t('common.closedCases', 'Closed cases')}
              </Text>
              <Card
                className="p-8 m-b-16 cardStyle"
                content={
                  <Text
                    className="caption-small colorGray5"
                    id={formatAutoId('closed-cases-desc')}
                    tabIndex={-1}
                  >
                    {t('common.noCases', 'There are currently no closed cases')}
                  </Text>
                }
              />
            </Container>
          )}
        </Column>
      </Section>
    </>
  )
}

export default React.memo(DeviceCases)
