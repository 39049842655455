export function formatCase(response) {
  const { Case } = response
  const ServiceOrders = Case?.ServiceOrders

  if (!ServiceOrders || ServiceOrders.length <= 0) {
    return getDefaultCaseDetails(Case)
  } else {
    ServiceOrders[0].History.push({
      ServiceOrderHistoryId: response.Status == 1 ? 1 : 0,
      Caption: 'common.caseClosed'
    })

    // set defult active list
    for (let i = ServiceOrders[0].History.length - 1; i >= 0; i--) {
      if (
        ServiceOrders[0].History[i] &&
        ServiceOrders[0].History[i].ServiceOrderHistoryId != 0
      ) {
        ServiceOrders[0].History[i].IsOpen = true
        break
      }
    }
    Case.ServiceOrders = ServiceOrders
    return getException(Case)
  }
}

function getDefaultCaseDetails(response) {
  const troubleshootCase = [
    {
      History: [
        {
          ServiceOrderHistoryId: undefined,
          ChangeDate: response?.CreatedDateTime,
          Caption: 'common.caseCreated',
          Title: response?.CaptionOpen,
          IsOpen: response?.Status == 1 ? false : true
        },
        {
          ServiceOrderHistoryId: response?.Status == 1 ? 1 : 0,
          ChangeDate: response?.DateClosed,
          Caption: 'common.caseClosed',
          Title: response?.CaptionClose,
          IsOpen: response?.Status == 1 ? true : false
        }
      ]
    }
  ]
  return {
    caseData: {
      ...response,
      ServiceOrders: troubleshootCase.slice()
    },
    exceptionData: null
  }
}

function getException(caseData) {
  const ServiceOrders = caseData.ServiceOrders
  const exceptionData = {}
  if (ServiceOrders[0].Exceptions.length > 0) {
    const ServiceOrderHistoryIdList: number[] = []
    let ServiceOrderHistoryId = 0
    for (let i = 0; i < ServiceOrders[0].History.length; i++) {
      ServiceOrderHistoryId = parseInt(
        ServiceOrders[0].History[i].ServiceOrderHistoryId
      )
      if (ServiceOrderHistoryId != 0)
        ServiceOrderHistoryIdList.push(ServiceOrderHistoryId)
    }

    for (let i = 0; i < ServiceOrderHistoryIdList.length; i++) {
      const curServiceOrderHistoryId = ServiceOrderHistoryIdList[i]
      const nextServiceOrderHistoryId = ServiceOrderHistoryIdList[i + 1]
      // if service order in sequence, there is no exception so skip excpetion loop
      if (curServiceOrderHistoryId != nextServiceOrderHistoryId - 1) {
        for (let j = 0; j < ServiceOrders[0].Exceptions.length; j++) {
          if (
            ServiceOrders[0].Exceptions[j].ServiceOrderHistoryId >
              curServiceOrderHistoryId &&
            (!nextServiceOrderHistoryId ||
              ServiceOrders[0].Exceptions[j].ServiceOrderHistoryId <
                nextServiceOrderHistoryId)
          ) {
            if (!exceptionData[curServiceOrderHistoryId])
              exceptionData[curServiceOrderHistoryId] = []
            exceptionData[curServiceOrderHistoryId].push(
              ServiceOrders[0].Exceptions[j]
            )
            ServiceOrderHistoryId = ServiceOrderHistoryId + 1
          }
        }
      }
    }
  }
  return {
    caseData,
    exceptionData
  }
}

export const fetchCaseByNumberMockup = function () {
  const response = {
    Case: {
      CaseID: '5150161961',
      Description:
        'My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display My PC has a red light coming from display',
      CaseStatus: 'Open',
      CreatedDateTime: '2019-04-23',
      ProductName: 'HP ZBook 15 G2',
      SerialNumber: 'CND5252BVP',
      Nickname: 'Living room printer',
      ServiceOrders: [
        {
          ServiceOrderId: 'BLJJ504492',
          OrderEntryDate: '2019-11-08T11:11:05Z',
          FlowType: 'ExchangeFlow',
          History: [
            {
              ServiceOrderHistoryId: '5938',
              ChangeDate: '2019-11-08T11:11:05Z',
              SOSStatus: 'SP',
              Caption: 'Service order created',
              Title:
                'Thank you for contacting HP Support. We have opened a support case for a replacement order for your product. A replacement product will be shipped to you within 2 to 5 business days.',
              ReplacementStatus: 'SP',
              LangCode: 'EN',
              OrderEntryDate: '2019-11-08T19:10:28Z',
              ServiceType: 'R__NR1',
              OutboundAirBillNo: null,
              ShipMethod: 'U2',
              IsOpen: true,
              ShipMethodDescription:
                'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
              InboundAirBillNo: null,
              InShipMethod: 'U5',
              InShipMethodDescription:
                'UPS Worldwide Express Plus Add Some Randdom Test Sting Bond. James Bond.',
              BoxAirBillNo: null,
              BoxShipMethod: 'F2',
              BoxShipMethodDescription:
                'FED-EX SECOND DAY Add Some Randdom Test Sting Keep your friends close, but your enemies closer.',
              ShipDate: null,
              DeliveryDate: null,
              ActualDeliveryDate: null,
              Status: 0,
              Tracking: [
                {
                  TrackingNumber: null,
                  ShipDescription:
                    'UPS 2nd Day Air Add Some Randdom Test Sting I drink your milkshake!',
                  Status: null,
                  StatusDate: null
                }
              ]
            },
            {
              ServiceOrderHistoryId: '5939',
              ChangeDate: '2019-11-08T11:11:06Z',
              SOSStatus: 'SC',
              Caption: 'Replacement shipped',
              Title: 'HP has shipped your replacement product.',
              ReplacementStatus: 'SP',
              LangCode: 'EN',
              OrderEntryDate: '2019-11-08T19:10:37Z',
              ServiceType: 'ISAGM3',
              OutboundAirBillNo: '789798442180',
              ShipMethod: 'F1',
              ShipMethodDescription:
                'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
              InboundAirBillNo: '789798442661',
              InShipMethod: 'F1',
              InShipMethodDescription:
                'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting Carpe diem. Seize the day, boys.',
              BoxAirBillNo: null,
              BoxShipMethod: 'F3',
              BoxShipMethodDescription:
                "FEDEX INTERNATIONAL PRIORITY 1-3 DAYS Add Some Randdom Test Sting I am serious. And don't call me Shirley.",
              ShipDate: '2019-11-08T19:10:40Z',
              DeliveryDate: null,
              ActualDeliveryDate: null,
              Status: 0,
              Tracking: [
                {
                  TrackingNumber: '789798442180',
                  ShipDescription:
                    'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
                  Status: null,
                  StatusDate: null
                }
              ]
            },
            {
              ServiceOrderHistoryId: '0',
              ChangeDate: '2019-11-08T11:11:06Z',
              SOSStatus: 'SC',
              Caption: 'Replacement shipped',
              Title: 'HP has shipped your replacement product.',
              ReplacementStatus: 'SP',
              LangCode: 'EN',
              OrderEntryDate: '2019-11-08T19:10:37Z',
              ServiceType: 'ISAGM3',
              OutboundAirBillNo: '789798442180',
              ShipMethod: 'F1',
              ShipMethodDescription:
                'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
              InboundAirBillNo: '789798442661',
              InShipMethod: 'F1',
              InShipMethodDescription:
                'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting Carpe diem. Seize the day, boys.',
              BoxAirBillNo: null,
              BoxShipMethod: 'F3',
              BoxShipMethodDescription:
                "FEDEX INTERNATIONAL PRIORITY 1-3 DAYS Add Some Randdom Test Sting I am serious. And don't call me Shirley.",
              ShipDate: '2019-11-08T19:10:40Z',
              DeliveryDate: null,
              ActualDeliveryDate: null,
              Status: 0,
              Tracking: [
                {
                  TrackingNumber: '789798442180',
                  ShipDescription:
                    'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
                  Status: null,
                  StatusDate: null
                }
              ]
            },
            {
              ServiceOrderHistoryId: '0',
              ChangeDate: '2019-11-08T11:11:06Z',
              SOSStatus: 'SC',
              Caption: 'Replacement shipped',
              Title: 'HP has shipped your replacement product.',
              ReplacementStatus: 'SP',
              LangCode: 'EN',
              OrderEntryDate: '2019-11-08T19:10:37Z',
              ServiceType: 'ISAGM3',
              OutboundAirBillNo: '789798442180',
              ShipMethod: 'F1',
              ShipMethodDescription:
                'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
              InboundAirBillNo: '789798442661',
              InShipMethod: 'F1',
              InShipMethodDescription:
                'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting Carpe diem. Seize the day, boys.',
              BoxAirBillNo: null,
              BoxShipMethod: 'F3',
              BoxShipMethodDescription:
                "FEDEX INTERNATIONAL PRIORITY 1-3 DAYS Add Some Randdom Test Sting I am serious. And don't call me Shirley.",
              ShipDate: '2019-11-08T19:10:40Z',
              DeliveryDate: null,
              ActualDeliveryDate: null,
              Status: 0,
              Tracking: [
                {
                  TrackingNumber: '789798442180',
                  ShipDescription:
                    'FED-EX NEXT DAY (10:30AM) Add Some Randdom Test Sting My precious.',
                  Status: null,
                  StatusDate: null
                }
              ]
            }
          ],
          Exceptions: [
            {
              ServiceOrderHistoryId: '5940',
              ChangeDate: '2019-11-08T11:11:06Z',
              SOSStatus: 'DP',
              Caption: 'Defective return pending',
              Title: 'Defective return pending',
              Description:
                'Please follow the packing and shipping instructions to return your defective part to HP.'
            },
            {
              ServiceOrderHistoryId: '5941',
              ChangeDate: '2019-11-08T11:11:06Z',
              SOSStatus: 'RC',
              Caption: 'Defective product/part received',
              Title: 'Defective product/part received',
              Description:
                'The HP Repair Facility has received your defective part.'
            },
            {
              ServiceOrderHistoryId: '5942',
              ChangeDate: '2019-11-08T11:12:05Z',
              SOSStatus: 'CH',
              Caption: 'Please contact HP',
              Title:
                'Your order could not be processed using the information we collected. We are attempting to contact you. If we have not done so, please contact HP to continue with your order. Refer to this Case # [CaseId] when speaking to a support agent.',
              Description:
                'Important: If we are unable to reach you, by phone and/or e-mail within 3 business days, your unit will be returned to you unrepaired.(due to credit card issues which customer needed more time. Agents will follow-up with these customers)'
            },
            {
              ServiceOrderHistoryId: '5943',
              ChangeDate: '2019-11-08T11:12:06Z',
              SOSStatus: 'CR',
              Caption: 'Please contact HP',
              Title:
                'The HP service center needs information from you to continue with the repairs on your PC. We are attempting to contact you. If we have not done so, please contact HP to continue with your order. Refer to this Case # [CaseId] when speaking to a support agent.',
              Description:
                'Important: If we are unable to reach you, by phone and/or e-mail within 3 business days, your unit will be returned to you unrepaired.(if customer needs to provide an answer or response this status is used)'
            },
            {
              ServiceOrderHistoryId: '5944',
              ChangeDate: '2019-11-08T11:12:06Z',
              SOSStatus: 'OH',
              Caption: 'On hold',
              Title:
                'Your order is currently on hold. HP will resolve the issue and resume processing within 24 hours.                  ',
              Description:
                'There is no need to contact HP. If we need to reach you a representative will contact you.(10% of all orders go on hold)'
            },
            {
              ServiceOrderHistoryId: '5945',
              ChangeDate: '2019-11-08T11:12:06Z',
              SOSStatus: 'OC',
              Caption: 'Order cancelled',
              Title: 'This order was cancelled. No further action is required.',
              Description: 'ServiceOrderExceptionDescription_OCText'
            },
            {
              ServiceOrderHistoryId: '5948',
              ChangeDate: '2019-11-08T11:12:06Z',
              SOSStatus: 'OC',
              Caption: 'Order cancelled',
              Title: 'This order was cancelled. No further action is required.',
              Description: 'ServiceOrderExceptionDescription_OCText'
            },
            {
              ServiceOrderHistoryId: '5949',
              ChangeDate: '2019-11-08T11:12:06Z',
              SOSStatus: 'OC',
              Caption: 'Order cancelled',
              Title: 'This order was cancelled. No further action is required.',
              Description: 'ServiceOrderExceptionDescription_OCText'
            }
          ]
        }
      ]
    }
  }
  return formatCase(response.Case)
}
