import React from 'react'
import { Accordion } from './styles'

const Timeline = ({
  data,
  cases,
  status,
  onCollapse,
  onExpand,
  show,
  isChild
}) => {
  return (
    <Accordion
      show={show}
      cases={cases}
      status={status}
      className="timeline"
      items={data}
      onCollapse={onCollapse}
      onExpand={onExpand}
      isChild={isChild}
    />
  )
}

export default Timeline
