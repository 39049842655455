import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import {
  Card,
  Column,
  Label,
  Line,
  Row,
  Toggle,
  Value,
  Spinner,
  FloatingButton
} from '../DeviceCases/styles'
import Button from '@veneer/core/dist/esm/scripts/button'
import CaseItem from '../CaseItem'
import { Skeleton } from '@jarvis/react-hpx-support-shared/dist/components/Skelton/Skeleton'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import IconWarning from '@veneer/core/dist/esm/scripts/icons/icon_warning'
import IconChevronDown from '@veneer/core/dist/esm/scripts/icons/icon_chevron_down'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useMediaQuery from '@jarvis/react-hpx-support-shared/dist/hooks/useMediaQuery'
import useIntl from '@jarvis/react-hpx-support-shared/dist/hooks/useIntl'
import useCaseDetails from '../../hooks/useCaseDetails'
import useContextAPI from '../../hooks/useContextAPI'
import { withAnalytics } from '@veneer/analytics'
import { genTestId } from '@jarvis/react-hpx-support-shared/dist/utils'
import { TestIDPrefix } from '../../utils/constants'
const TrackButton = withAnalytics(Button)

const ProductIconLazy = React.lazy(
  () => import('@jarvis/react-hpx-support-shared/dist/components/ProductIcon')
)

const ProductIcon = ({ size, color, type }) => {
  return (
    <Suspense fallback={<Skeleton count={1} height={size} width={size} />}>
      <ProductIconLazy size={size} color={color} type={type} />
    </Suspense>
  )
}

const CaseCard = ({ item, device }) => {
  const { CaseId } = item
  const { t } = useI18n()
  const {
    formatAutoId,
    environment: { isMobile },
    navigation,
    navigatePath
  } = useContextAPI()
  const { getDateLocale } = useIntl()
  const {
    show,
    setShow,
    caseDetails,
    loading,
    closeCase,
    isCloseRequested,
    fetchCaseDetails
  } = useCaseDetails({
    CaseId
  })

  const { history, exception, caseData } = caseDetails
  const refCard = useRef<HTMLElement>()
  const matches = useMediaQuery(`(max-width: 639px)`)
  const [minHeight, setMinHeight] = useState('66px')

  const CASE_STATUS = {
    0: t('common.open'),
    1: t('common.closed'),
    2: t('common.requestClosure')
  }

  const setShowMemo = useCallback((show) => {
    setShow(show)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (show) fetchCaseDetails(CaseId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useLayoutEffect(() => {
    if (matches) setMinHeight(item.Status !== 0 ? '130px' : '140px')
    else setMinHeight(item.Status !== 0 ? '60px' : '66px')
  }, [matches, item])

  useLayoutEffect(() => {
    refCard?.current?.setAttribute?.('role', '')
  }, [])

  const router = (navigation, deviceId, routeName, params) => {
    // navigation.push(`/support/device/${deviceId}/${routeName}`, {
    navigation.push(`/${navigatePath}/${deviceId}/${routeName}`, {
      type: routeName,
      navBarTitle: 'common.deviceSupport',
      ...params
    })
  }

  const mobileCaseItem = () => {
    return (
      <>
        <Row className="row">
          <Column align="center" className="col-6 col-sm-12 col-md-5">
            <Row>
              <Column flex="1">
                <Row>
                  <Value className="body" flex={1}>
                    {t('common.repairCases')}
                  </Value>
                </Row>
                <Row flex={item?.Status != 0 ? 1 : 0} align="center">
                  <Label
                    className="caption"
                    id={formatAutoId('cases-date-created')}
                    tabIndex={-1}
                    role="text"
                  >
                    {getDateLocale(item?.DateCreated)}
                  </Label>
                </Row>
              </Column>
            </Row>
            <FloatingButton className={'media-small'}>
              <IconChevronRight size={24} color="colorHpBlue6" />
            </FloatingButton>
          </Column>
        </Row>
      </>
    )
  }

  const windonsCaseItem = () => {
    return (
      <>
        <Toggle
          duration={500}
          delay={500}
          minHeight={minHeight}
          show={show && (!loading || caseData)}
          className="full-width"
        >
          {loading && (
            <Spinner>
              <ProgressIndicator
                appearance="circular"
                behavior="indeterminate"
              />
            </Spinner>
          )}
          <Row show={show && (!loading || caseData)} className="row">
            <Column className="col-6 col-sm-12 col-md-5">
              <Row className={'m-b-30 m-t-8'}>
                <Column className="p-r-16">
                  {device?.ImageLink && (
                    <img style={{ width: '60px' }} src={device?.ImageLink} />
                  )}
                  {!device?.ImageLink && (
                    <ProductIcon
                      size={45}
                      type={device?.ProductType}
                      color="colorForegroundLight"
                    ></ProductIcon>
                  )}
                </Column>
                <Column flex="1">
                  <Row>
                    {item?.Status == 0 && (
                      <Value className="body" flex={1}>
                        {t('common.caseCreated')}
                      </Value>
                    )}
                    <FloatingButton className={'media-small'}>
                      <IconChevronDown size={24} color="colorHpBlue6" />
                    </FloatingButton>
                  </Row>
                  <Row flex={item?.Status != 0 ? 1 : 0} align="center">
                    <Label
                      className="caption"
                      id={formatAutoId('cases-date-created')}
                      tabIndex={-1}
                      role="text"
                    >
                      {getDateLocale(item?.DateCreated)}
                    </Label>
                  </Row>
                </Column>
              </Row>
              {(!show || loading) && (
                <Row className={'media-small'}>
                  <Row flex={1}>
                    <Column flex="auto">
                      <Label
                        className="caption"
                        id={formatAutoId('cases-status-label')}
                        tabIndex={-1}
                        role="text"
                      >
                        {t('common.status')}
                      </Label>
                      <Value
                        medium={true}
                        className="caption"
                        id={formatAutoId('cases-status-value')}
                        tabIndex={-1}
                        role="text"
                      >
                        {CASE_STATUS[item?.Status]}
                      </Value>
                    </Column>
                    <Column flex="auto">
                      <Label
                        className="caption"
                        id={formatAutoId('cases-caseid-label')}
                        tabIndex={-1}
                        role="text"
                      >
                        {t('common.caseID')}
                      </Label>
                      <Value
                        medium={true}
                        className="caption"
                        x-ms-format-detection="none"
                        id={formatAutoId('cases-caseid-value')}
                        tabIndex={-1}
                        role="text"
                      >
                        {item.CaseId}
                      </Value>
                    </Column>
                    <Column flex="auto">
                      <Label
                        className="caption"
                        id={formatAutoId('cases-product-number-label')}
                        tabIndex={-1}
                        role="text"
                      >
                        {t('common.productNum', 'Product Num.')}
                      </Label>
                      <Value
                        medium={true}
                        className="caption"
                        id={formatAutoId('cases-product-number-value')}
                        tabIndex={-1}
                        role="text"
                      >
                        {device?.ProductNumber}
                      </Value>
                    </Column>
                    <Column flex="auto">
                      <Label
                        className="caption"
                        id={formatAutoId('cases-serial-number-label')}
                        tabIndex={-1}
                        role="text"
                      >
                        {t('common.serialNum', {
                          defaultValue: 'Serial Num.'
                        })}
                      </Label>
                      <Value
                        medium={true}
                        className="caption"
                        id={formatAutoId('cases-serial-number-value')}
                        tabIndex={-1}
                        role="text"
                      >
                        {device?.SerialNumber}
                      </Value>
                    </Column>
                  </Row>
                </Row>
              )}
              {caseData && (
                <>
                  <Row className={show && !loading && 'sm-hide'}>
                    <Column flex={1}>
                      <Row>
                        <Label
                          className="caption"
                          id={formatAutoId('cases-product-number-label')}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.productNumber', 'Product Number')}
                        </Label>
                        <Value
                          medium={true}
                          className="caption"
                          id={formatAutoId('cases-product-number-value')}
                          tabIndex={-1}
                          role="text"
                        >
                          {device?.ProductNumber}
                        </Value>
                      </Row>
                      <Row>
                        <Label
                          className="caption"
                          id={formatAutoId('cases-serial-number-label')}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.serialNumber')}
                        </Label>
                        <Value
                          medium={true}
                          className="caption"
                          id={formatAutoId('cases-serial-number-value')}
                          tabIndex={-1}
                          role="text"
                        >
                          {device?.SerialNumber}
                        </Value>
                      </Row>
                    </Column>
                  </Row>

                  <Row className={'media-small'}>
                    <Row flex={1}>
                      <Column flex="auto">
                        <Label
                          className="caption"
                          id={formatAutoId('cases-status-label')}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.status')}
                        </Label>
                        <Value
                          medium={true}
                          className="caption"
                          id={formatAutoId('cases-status-value')}
                          tabIndex={-1}
                          role="text"
                        >
                          {CASE_STATUS[item?.Status]}
                        </Value>
                      </Column>
                      <Column flex="auto">
                        <Label
                          className="caption"
                          id={formatAutoId('cases-caseid-label')}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.caseID')}
                        </Label>
                        <Value
                          medium={true}
                          className="caption"
                          x-ms-format-detection="none"
                          id={formatAutoId('cases-caseid-value')}
                          tabIndex={-1}
                          role="text"
                        >
                          {item.CaseId}
                        </Value>
                      </Column>
                      <Column flex="auto">
                        <Label
                          className="caption"
                          id={formatAutoId('cases-product-number-label')}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.productNum', 'Product Num.')}
                        </Label>
                        <Value
                          medium={true}
                          className="caption"
                          id={formatAutoId('cases-product-number-value')}
                          tabIndex={-1}
                          role="text"
                        >
                          {device?.ProductNumber}
                        </Value>
                      </Column>
                      <Column flex="auto">
                        <Label
                          className="caption"
                          id={formatAutoId('cases-serial-number-label')}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.serialNum', {
                            defaultValue: 'Serial Num.'
                          })}
                        </Label>
                        <Value
                          medium={true}
                          className="caption"
                          id={formatAutoId('cases-serial-number-value')}
                          tabIndex={-1}
                          role="text"
                        >
                          {device?.SerialNumber}
                        </Value>
                      </Column>
                    </Row>
                  </Row>
                  <Row>
                    <Line></Line>
                  </Row>
                  <Row>
                    <Column>
                      <Value
                        className="caption"
                        id={formatAutoId('cases-description-label')}
                        tabIndex={-1}
                        role="text"
                      >
                        {t('common.description')}
                      </Value>
                      <Value
                        medium={true}
                        id={formatAutoId('cases-description-value')}
                        tabIndex={-1}
                        role="text"
                        className="caption case-desc-small break-text"
                      >
                        {caseData?.Description}
                      </Value>
                    </Column>
                  </Row>
                  <Row>
                    <Column className="close-case" flex={1}>
                      {show && (
                        <TrackButton
                          id={formatAutoId(`cases-button-${CaseId}`)}
                          disabled={isCloseRequested || caseData.Status !== 0}
                          trackId={genTestId(TestIDPrefix, `CloseCase`)}
                          trackCategory={`CaseId_${CaseId}`}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            closeCase()
                          }}
                        >
                          {t('common.closeCase', 'Close Case')}
                        </TrackButton>
                      )}
                    </Column>
                  </Row>
                  <Row>
                    <Column className="line" flex={1}>
                      <hr />
                    </Column>
                  </Row>
                </>
              )}
            </Column>
            {/* to show in small screen
          <Column className="col-sm-3">
            {(!show || loading) && (
              <CaseItem
                show={show}
                setShow={setShowMemo}
                history={[{}]}
                caseDetails={item}
                exception={exception}
              ></CaseItem>
            )}
          </Column>*/}
            <Column className="col-6 col-sm-12 col-md-7">
              {show && (isCloseRequested || caseData?.Status == '2') && (
                <Row className="m-l-60 align-center">
                  <IconWarning size={24} color="colorOrange5"></IconWarning>
                  <span
                    className="caption m-l-8"
                    id={formatAutoId('cases-request-to-close')}
                    tabIndex={-1}
                    role="text"
                  >
                    {t(
                      'common.requestCloseStatus',
                      'Your request to close this case is pending and could take up to 24 hours to update.'
                    )}
                  </span>
                </Row>
              )}
              <CaseItem
                setShow={setShowMemo}
                show={show}
                caseDetails={item}
                history={history || [{}]}
                exception={exception}
              ></CaseItem>
            </Column>
          </Row>
        </Toggle>
      </>
    )
  }
  return (
    <Card
      ref={refCard}
      status={item?.Status != 0 && !matches}
      className="cardStyle pointer"
      id={formatAutoId(`case-card-${CaseId}`)}
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault()
        if (isMobile) {
          router(navigation, device?.DeviceId, 'case/caseDetail', {
            device,
            item
          })
        } else {
          setShow(!show)
        }
      }}
      appearance="outlined"
      content={isMobile ? mobileCaseItem() : windonsCaseItem()}
    ></Card>
  )
}

export default CaseCard
