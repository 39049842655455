const breakpoints = {
  L: 1008,
  M: 640,
  S: 500
}

const LEFT_MENU_SIZE = 48

const rtl =
  (left, right) =>
  ({ theme }) =>
    theme.dir == 'rtl' ? right : left
export const left = rtl('left', 'right')
export const right = rtl('right', 'left')

export const HPXTheme = {
  breakpoints,
  mediaQueries: {
    XL: `@media only screen and (min-width: ${breakpoints.L}px)`,
    L: `@media only screen and (max-width: ${
      breakpoints.L - 1
    }px) and (min-width: ${breakpoints.L - LEFT_MENU_SIZE}px)`,
    M: `@media only screen and (max-width: ${
      breakpoints.L - LEFT_MENU_SIZE - 1
    }px) and (min-width: ${breakpoints.M}px)`,
    S: `@media only screen and (max-width: ${breakpoints.M - 1}px)`
  }
}

export default HPXTheme
