import React, { memo, ReactNode, useEffect, useState } from 'react'
import { Block } from './styles'

interface IProps {
  show: boolean
  delay?: number
  children: ReactNode
  minHeight?: string
}

const CollapsibleBlock: React.FC<IProps> = ({
  show,
  children,
  delay = 250,
  minHeight = '0px'
}) => {
  const [height, setHeight] = useState<string | number>('0px')
  const [divRef, setDivRef] = useState<HTMLDivElement>(null)

  useEffect(() => {
    if (show) setHeight(`${divRef?.scrollHeight}px`)
    else
      setTimeout(() => {
        setHeight(minHeight)
      }, 1)
  }, [minHeight, divRef?.scrollHeight, show])

  const setInnerRef = (ref: HTMLDivElement) => setDivRef(ref)

  const updateAfterTransition = () => {
    if (show) {
      setHeight('auto')
      setTimeout(() => {
        setHeight(`${divRef?.scrollHeight}px`)
      }, 1)
    }
  }

  return (
    <>
      <Block
        ref={setInnerRef}
        onTransitionEnd={() => updateAfterTransition()}
        height={height}
        delay={delay}
      >
        {children}
      </Block>
    </>
  )
}

export default memo(CollapsibleBlock)
