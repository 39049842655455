import { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { IProfile } from '@jarvis/web-support-commons/dist/methone'
import { LocalizationType } from '../types/localization'

interface IContext {
  formatAutoId?: (val: string) => string
  authProvider?: IAuthProvider
  dir?: string
  localization?: LocalizationType
  environment?: IEnvironment
  navigation?: any
  navigatePath?: string
}
interface IEnvironment {
  platform: string
  isNative: boolean
  isMobile: boolean
}

interface IAuthProvider {
  setAuthToken: () => Promise<CoptorAuth | string>
  refreshToken: () => Promise<CoptorAuth | string>
  checkIsLoggedIn: () => Promise<boolean>
  checkAuth: () => Promise<boolean>
  setIsSignedIn: (isSignedIn: boolean) => void
  isSignedIn: boolean
  auth: CoptorAuth | string
  profile: IProfile
  logout: () => void
}

const useContextAPI = () => {
  const {
    formatAutoId,
    authProvider,
    dir,
    localization,
    environment,
    navigation,
    navigatePath
  } = useContext<IContext>(RootContext)
  return {
    formatAutoId,
    authProvider,
    dir,
    localization,
    environment,
    navigation,
    navigatePath
  }
}

export default useContextAPI
