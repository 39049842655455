import React, { memo, ReactNode, useCallback, useEffect } from 'react'
import {
  Column,
  Description,
  Row,
  Title,
  Text,
  Label
} from '../DeviceCases/styles'
import Timeline from '../Timeline'
import { History } from '@jarvis/web-support-commons/dist/methone/cases/types/casedetails'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useContextAPI from '../../hooks/useContextAPI'

interface Items {
  content: ReactNode
  disabled?: boolean
  expanded: boolean
  header: Header
  id?: string
}

interface Header {
  ariaLabel: string
  startArea: ReactNode
  centralArea?: ReactNode
  endArea?: ReactNode
  id?: string
}

interface IException {
  [key: string]: History[]
}

interface IProps {
  setShow?: (show) => void
  history: any
  caseDetails?: any
  exception?: IException
  isChild?: boolean
  show?: boolean
}

const useTimeline = ({
  history,
  caseDetails,
  exception,
  isChild = false,
  setShow,
  show
}) => {
  const { t } = useI18n()
  const [data, setData] = React.useState<Items[]>([])
  const { formatAutoId } = useContextAPI()

  const CASE_STATUS = {
    0: t('common.open'),
    1: t('common.closed'),
    2: t('common.requestClosure')
  }

  const headerItem = useCallback(
    (item, index: number): Header => {
      const headerId = formatAutoId(
        `cases-tab-header-${
          caseDetails?.CaseId || item?.ServiceOrderHistoryId
        }-${index}`
      )
      const header: Header = {
        ariaLabel: t(item.Caption),
        id: headerId,
        startArea: (
          <>
            {(isChild || index != 0) && (
              <Title
                className="caption"
                id={formatAutoId(
                  `cases-title-${
                    caseDetails?.CaseId || item?.ServiceOrderHistoryId
                  }-${index}`
                )}
                tabIndex={-1}
                role="text"
              >
                {t(item.Caption)}
              </Title>
            )}
            {!isChild && index == 0 && (
              <>
                <Title
                  onClick={(e) => handleClick(e, headerId)}
                  className="caption media-small"
                  id={formatAutoId(
                    `cases-title-${
                      caseDetails?.CaseId || item?.ServiceOrderHistoryId
                    }-${index}`
                  )}
                  tabIndex={-1}
                  role="text"
                >
                  {t(item.Caption)}
                </Title>
                <Row
                  onClick={(e) => handleClick(e, headerId)}
                  flex={1}
                  className="media-large"
                >
                  <Column flex={1} className="center">
                    <Column>
                      <Row flex={1}>
                        <Label
                          className="caption m-b-8"
                          id={formatAutoId(
                            `cases-status-label-${
                              caseDetails?.CaseId || item?.ServiceOrderHistoryId
                            }-${index}`
                          )}
                          tabIndex={-1}
                          role="text"
                        >
                          {t('common.status')}
                        </Label>
                      </Row>
                      <Row flex={1}>
                        <Text
                          className="caption"
                          id={formatAutoId(
                            `cases-status-value-${
                              caseDetails?.CaseId || item?.ServiceOrderHistoryId
                            }-${index}`
                          )}
                          tabIndex={-1}
                          role="text"
                        >
                          {CASE_STATUS[caseDetails?.Status]}
                        </Text>
                      </Row>
                    </Column>
                  </Column>
                  <Column flex={1}>
                    <Row flex={1}>
                      <Label
                        className="caption m-b-8"
                        id={formatAutoId(
                          `cases-caseid-label-${
                            caseDetails?.CaseId || item?.ServiceOrderHistoryId
                          }-${index}`
                        )}
                        tabIndex={-1}
                        role="text"
                      >
                        {t('common.caseID')}
                      </Label>
                    </Row>
                    <Row flex={1}>
                      <Text
                        className="caption"
                        x-ms-format-detection="none"
                        id={formatAutoId(
                          `cases-caseid-value-${
                            caseDetails?.CaseId || item?.ServiceOrderHistoryId
                          }-${index}`
                        )}
                        tabIndex={-1}
                        role="text"
                      >
                        {caseDetails?.CaseId}
                      </Text>
                    </Row>
                  </Column>
                </Row>
              </>
            )}
          </>
        )
      }
      return header
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [caseDetails, isChild]
  )
  const caseItem = useCallback(
    (item, index) => {
      return {
        content: (
          <>
            {!isChild && index == 0 && (
              <Title
                className="caption media-large"
                id={formatAutoId(`cases-caption-${caseDetails?.CaseId}`)}
                tabIndex={-1}
                role="text"
              >
                {t(item.Caption)}
              </Title>
            )}
            <Description
              className="caption"
              id={formatAutoId(`cases-title-${caseDetails?.CaseId}`)}
              tabIndex={-1}
              role="text"
            >
              {item.Title}
            </Description>
            {exception && exception[item.ServiceOrderHistoryId] && (
              <CaseItem
                history={exception[item.ServiceOrderHistoryId]}
                isChild={true}
              ></CaseItem>
            )}
          </>
        ),
        disabled: false,
        expanded: show && !isChild && index == 0 ? true : false,
        header: headerItem(item, index),
        id: formatAutoId(`cases-tab-item-${caseDetails?.CaseId}-${index}`)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show, caseDetails, exception]
  )

  useEffect(() => {
    const caseItems = () => {
      return (
        history?.length > 0 &&
        history.map((item, index) => {
          return caseItem(item, index)
        })
      )
    }
    const items = caseItems()
    if (items) setData(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.length, show])

  const handleClick = (event, headerId) => {
    event.stopPropagation()
    const elem = document.querySelector(`#${headerId}`) as HTMLElement
    if (elem) elem.click()
  }

  const handleExpand = (event, index) => {
    event.stopPropagation()
    if (data) {
      const updatedData = data.slice()
      updatedData[index].expanded = true
      setData(updatedData)
      if (setShow) setShow(true)
    }
    event?.target?.setAttribute('accordion', 'true')
    return
  }

  const handleCollapse = (event, index) => {
    event.stopPropagation()
    if (data) {
      const updatedData = data.slice()
      updatedData[index].expanded = false
      setData(updatedData)
      if (setShow && index == 0 && isChild === false) setShow(false)
    }
    event?.target?.setAttribute('accordion', 'true')
    return
  }
  return {
    data,
    setData,
    handleCollapse,
    handleExpand
  }
}

const CaseItem = memo(
  ({
    history,
    caseDetails,
    exception,
    isChild = false,
    setShow,
    show
  }: IProps) => {
    const { data, handleCollapse, handleExpand } = useTimeline({
      history,
      setShow,
      caseDetails,
      exception,
      isChild,
      show
    })
    return (
      <>
        {history?.length > 0 && data?.length > 0 && (
          <Timeline
            show={show}
            data={data}
            cases={history}
            status={caseDetails?.Status}
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            isChild={isChild}
          />
        )}
      </>
    )
  }
)

export default CaseItem
